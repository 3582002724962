export const TEXTS = [
  "Har $name glemt sine sko?",
  "Er $name gået glip af en kamp?",
  "Er $name kommet for sent til træning?",
  "Har $name fået rødt kort?",
  "Har $name fået gult kort?",
  "Har $name fået en udvisning?",
  "Har $name misset et straffe?",
  "Har $name filmet en skade?",
];

export const NAMES = [
  "Peter",
  "Michael",
  "Jens",
  "Lars",
  "Thomas",
  "Henrik",
  "Søren",
  "Christian",
  "Jan",
  "Martin",
  "Niels",
  "Anders",
  "Morten",
  "Jesper",
  "Mads",
  "Rasmus",
  "Hans",
  "Per",
  "Jørgen",
  "Ole",
  "Anne",
  "Kirsten",
  "Mette",
  "Hanne",
  "Helle",
  "Jytte",
  "Lene",
  "Susanne",
  "Marianne",
  "Lone",
  "Camilla",
  "Pia",
  "Louise",
  "Møller",
];
