import React from "react";
import { Link } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";

function Terms() {
  return (
    <div className="bg-brand-bg relative h-full flex flex-col overflow-scroll">
      <div className="max-w-[650px] mx-auto p-[18px] mb-[150px]">
        <div className="flex flex-col justify-center h-[180px]">
          <Link
            className="cursor-pointer mb-[10px] hover:-translate-x-2 transition duration-200"
            to="/"
          >
            <BsArrowLeft size="20px" />
          </Link>

          <h1 className="font-extrabold text-[32px] ">Betingelser</h1>
        </div>

        <BigHeader title="1. Ansvarsfraskrivelse" />
        <Paragraph>
          PayTheHippo gør sit bedste for at sikre, at alle informationer og
          funktioner på hjemmesiden er så akkurate, opdaterede og præcise som
          muligt, men vi fraskriver os ethvert ansvar for tab eller skade, -
          såvel direkte som indirekte - der måtte opstå som følge af brugen af
          informationerne og funktionerne på hjemmesiden samt på vores mobile
          applikationer. Vi anbefaler derfor altid, at brugeren laver back-up af
          vigtige informationer.
        </Paragraph>
        <Paragraph>
          PayTheHippo kan ikke gøres ansvarlig for skade, der er en følge af
          urigtig brug af applikationen.
        </Paragraph>
        <Paragraph>
          Der forefindes eksterne links til tredjemand på hjemmesiden.
          PayTheHippo er uden ansvar for pålideligheden og indholdet af siderne,
          der linkes til. PayTheHippo har dog – da links til eksterne sider blev
          oprettet – foretaget en vurdering af de enkelte links og indholdet
          heraf.
        </Paragraph>
        <Paragraph>
          PayTheHippo har ophavsretten til egne logoer i enhver henseende. En
          gengivelse af PayTheHippos grafiske logoer i andre elektroniske og
          trykte publikationer er ikke tilladt uden PayTheHippos udtrykkelige
          samtykke.
        </Paragraph>
        <Paragraph>
          <>
            På hjemmesiden vil der ikke kunne findes informationer eller
            services, der skønnes at være i strid med dansk lovgivning. Skulle
            der – mod forventning – forekomme indhold, der kunne stride mod
            dansk lov modtager vi gerne besked herom på{" "}
            <a
              className="underline font-semibold hover:no-underline"
              href="mailto:info@wemuda.com"
            >
              info@wemuda.com
            </a>
            . PayTheHippo kan ikke stilles til ansvar for brugergenereret
            indhold på hjemmesiden, der strider mod dansk lovgivning på området.
            PayTheHippo forbeholder sig ret til at fjerne brugergenereret
            indhold, der kan anses som stødeligt, krænkende eller i strid med
            dansk lov på området.
          </>
        </Paragraph>
        <Paragraph>
          Denne ansvarsfraskrivelse er en integreret del af hjemmesiden for
          PayTheHippo. Skulle dele af teksten eller enkelte formuleringer i
          ovennævnte ikke eller kun delvist være i overensstemmelse med gældende
          ret, betyder dette ikke, at resten af bestemmelserne bliver
          uvirksomme.
        </Paragraph>
        <Paragraph>
          <>
            Eventuelle tvivlsspørgsmål kan stilles til{" "}
            <a
              className="underline font-semibold hover:no-underline"
              href="mailto:info@wemuda.com"
            >
              info@wemuda.com
            </a>
            .
          </>
        </Paragraph>

        <BigHeader title="2. Privatlivspolitik" />
        <Paragraph>
          <>
            <UnderTitle>Brug af personoplysninger</UnderTitle>
            Personoplysninger afgives aldrig til tredjepart, med mindre du selv
            udtrykkeligt giver tilsagn hertil, og vi indsamler aldrig
            personoplysninger, uden at du selv har givet os disse oplysninger
            ved registrering, køb eller deltagelse i en undersøgelse m.v. Her
            indsamles oplysninger om navn, adresse, postnummer, e-mail, køn,
            alder, interesser, holdninger og kendskab til forskellige emner.
          </>
        </Paragraph>
        <Paragraph>
          Personoplysninger bruges til at gennemføre det køb eller den service,
          som oplysningerne er indsamlet i forbindelse med. Oplysningerne bruges
          derudover til at få større kendskab til dig og øvrige brugere af
          websitet. Denne brug kan bl.a. omfatte undersøgelser og analyser, der
          er rettet mod en forbedring af vore produkter, tjenester og
          teknologier, samt visning af indhold og reklamer, der er tilpasset
          dine interesser og hobbyer.
        </Paragraph>
        <Paragraph>
          <>
            <UnderTitle>Kontakt vedr. personoplysninger</UnderTitle>
            Hvis du ønsker adgang til de oplysninger, som er registreret om dig
            hos PayTheHippo, skal du rette henvendelse på{" "}
            <a
              className="underline font-semibold hover:no-underline"
              href="mailto:info@wemuda.com"
            >
              info@wemuda.com
            </a>
            . Er der registreret forkerte data, eller har du andre indsigelser,
            kan du rette henvendelse samme sted. Du har mulighed for at få
            indsigt i, hvilke informationer der er registreret om dig, og du kan
            gøre indsigelse mod en registrering i henhold til reglerne i
            Persondataloven.
          </>
        </Paragraph>
        <Paragraph>
          <>
            <UnderTitle>Beskyttelse af personoplysninger</UnderTitle>
            Ifølge persondataloven skal dine personlige oplysninger opbevares
            sikkert og fortroligt. Vi gemmer dine personlige oplysninger på
            computere med begrænset adgang, som er placeret i kontrollerede
            faciliteter, og vores sikkerhedsforanstaltninger kontrolleres
            løbende for at afgøre, om vores brugeroplysninger håndteres
            forsvarligt, og under stadig hensyntagen til dine rettigheder som
            bruger. Vi kan dog ikke garantere 100 procent sikkerhed ved
            dataoverførsler via internettet. Det betyder, at der kan være en
            risiko for, at andre uberettiget tiltvinger sig adgang til
            oplysninger, når data sendes og opbevares elektronisk. Du afgiver
            således dine personlige oplysninger på eget ansvar.
          </>
        </Paragraph>
        <Paragraph>
          Personoplysninger slettes eller anonymiseres løbende efterhånden som
          det formål, de blev indsamlet til, afsluttes. Personoplysninger gemmes
          højst i 12 måneder efter brug.
        </Paragraph>
        <Paragraph>
          <>
            <UnderTitle>Generelt</UnderTitle>
            Den hurtige udvikling af internettet betyder, at ændringer i vores
            behandling af personoplysninger kan blive nødvendige. Vi forbeholder
            os derfor ret til at opdatere og ændre nærværende retningslinjer for
            behandling af personoplysninger. Gør vi det, retter vi selvfølgeligt
            datoen for "sidst opdateret" nederst på siden. I tilfælde af
            væsentlige ændringer giver vi dig besked i form af en synlig
            meddelelse på vores websites.
          </>
        </Paragraph>
        <Paragraph>
          I det omfang, der behandles personoplysninger om dig, har du ifølge
          persondataloven ret til at få oplyst, hvilke personoplysninger, der
          kan henføres til dig. Såfremt det viser sig, at de oplysninger eller
          data, der behandles om dig, er urigtige eller vildledende, har du ret
          til at kræve disse berigtiget, slettet eller blokeret. Du kan til
          enhver tid gøre indsigelse mod, at oplysninger om dig gøres til
          genstand for behandling. Du kan også til enhver tid tilbagekalde dit
          samtykke. Du har mulighed for at klage over behandlingen af
          oplysninger og data vedrørende dig. Klage indgives til Datatilsynet,
          jf. persondatalovens § 58, stk. 1.
        </Paragraph>
      </div>
    </div>
  );
}

export default Terms;

const BigHeader = ({ title }: { title: string }) => {
  return (
    <h2 className="font-extrabold text-[24px] mb-[18px] mt-[50px]">{title}</h2>
  );
};

const UnderTitle = ({ children }: { children: React.ReactChild }) => {
  return (
    <div className="font-bold text-[16px] mb-[2px] mt-[30px] leading-[28px]">
      {children}
    </div>
  );
};

const Paragraph = ({ children }: { children: React.ReactChild }) => {
  return (
    <div className="font-normal text-[16px] mb-[22px] leading-[28px]">
      {children}
    </div>
  );
};
